@font-face {
  font-family: 'Avalon'; /*Can be any text*/
  src: local('AvalonRegular'), url('./assets/font/Avalon.ttf');
}
@font-face {
  font-family: 'Avalon Bold';
  src:
    local('Avalon Bold Cond'),
    local('Avalon-Bold-Cond'),
    url('./assets/font/Avalon-BoldCond.woff2') format('woff2'),
    url('./assets/font/Avalon-BoldCond.woff') format('woff'),
    url('./assets/font/Avalon-BoldCond.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon Demi';
  src: local('Avalon Demi'), local('Avalon-Demi'),
      url('./assets/font/Avalon-Demi.woff2') format('woff2'),
      url('./assets/font/Avalon-Demi.woff') format('woff'),
      url('./assets/font/Avalon-Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon Medium';
  src: local('Avalon Medium'), local('Avalon-Medium'),
      url('./assets/font/Avalon-Medium.woff2') format('woff2'),
      url('./assets/font/Avalon-Medium.woff') format('woff'),
      url('./assets/font/Avalon-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon Regular';
  src: local('Avalon Regular'), local('Avalon-Regular'),
      url('./assets/font/Avalon-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Avalon', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 5px;
}